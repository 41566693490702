import "./Background.css";

const Background = () => {
  return (
    <div className='BackgroundDiv'>
      
    </div>
  )
}

export default Background
