import './Line.css';

const Line = ({height, width, color}) => {
  return (
    <div className='LineDiv' style={{height: height, width: width, backgroundColor: color}}>
        
    </div>
  )
}

export default Line
